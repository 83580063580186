import { connect } from "react-redux"
import React, { Component } from "react"
import { pure, compose } from "recompose"
import { Link, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"

import * as masterActions from "../actions/master"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  classes: any
}

class PrivacyPage extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <Layout>
        <SEO title="特定商取引法に基づく表記" />
        <div className={classes.root}>
          <div className={classes.componentWrapper}>
            <section id="privacy pt20">
              <div className="title-wrapper pt20">
                <h1 className={classNames("title", classes.title)}>
                  特定商取引法に基づく表記
                </h1>
              </div>
              <div className={classes.content}>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>販売事業者</th>
                      <td>株式会社COZOU</td>
                    </tr>
                    <tr>
                      <th>代表責任者</th>
                      <td>代表取締役社長 鈴木良介</td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>
                        【代表】
                        <br />
                        〒165-0033 東京都中野区若宮3丁目40-10
                        <br />
                        【MIFAサッカースクール】
                        <br />
                        〒190-0015 東京都立川市泉町935番1外
                        ららぽーと立川立飛近接
                      </td>
                    </tr>
                    <tr>
                      <th>公開メールアドレス</th>
                      <td>
                        【代表】
                        <br />
                        info@cozou.co.jp
                        <br />
                        【MIFAサッカースクール】
                        <br />
                        info@mifa-ss.com
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>050-5873-6642</td>
                    </tr>
                    <tr>
                      <th>ホームページURL</th>
                      <td>
                        【代表】
                        <br />
                        https://cozou.co.jp
                        <br />
                        【MIFAサッカースクール】
                        <br />
                        https://tachikawa.mifafootballpark.com/mifa-soccer-school/
                      </td>
                    </tr>
                    <tr>
                      <th>販売価格</th>
                      <td>
                        商品ごとに販売価格を表示（税込）
                        <br />
                        申込方法：指定のフォーム、もしくはメールを使用
                      </td>
                    </tr>
                    <tr>
                      <th>商品代金以外の必要料金</th>
                      <td>銀行振込手数料は購入者にて負担</td>
                    </tr>
                    <tr>
                      <th>引き渡し時期</th>
                      <td>トレーニングの予約日当日</td>
                    </tr>
                    <tr>
                      <th>支払い方法</th>
                      <td>クレジットカード、銀行振込</td>
                    </tr>
                    <tr>
                      <th>支払時期</th>
                      <td>
                        クレジットカード：各カード会社引き落とし日
                        <br />
                        銀行振込：トレーニングの2日前まで
                      </td>
                    </tr>
                    <tr>
                      <th>キャンセル等</th>
                      <td>
                        トレーニング実施前は、各手数料を差し引いた金額を返金します。
                        <br />
                        途中解約の場合は、進行状況を考慮した金額を返金します。
                        <br />
                        トレーニング終了後の返金は対応していません。
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 50,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 980,
    padding: "40px 80px",
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 20px",
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  label: {
    fontSize: 20,
    fontWeight: "bold",
  },
  list: { marginBottom: 4 },
  content: {
    paddingTop: 50,
  },
})

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master

  return {
    isProcessing,
  }
}

const connector = connect(mapStateToProps, { ...masterActions })

export default compose(pure, withStyles(styles), connector)(PrivacyPage)
